import Header from "../../components/header/Header";
import Navigation from "../../components/header/navigation/Navigation";
import styles from "./Home.module.css";

const Home = () => {
  return (
    <div className={styles["home"]}>
      <Navigation />
      <Header />
    </div>
  );
};
export default Home;
