import styles from "./Navigation.module.css";
import menuButtonIcon from "../../../assets/icons/menu-button.svg";
import bellIcon from "../../../assets/icons/bell.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import homeIcon from "../../../assets/icons/home.svg";
import exploreIcon from "../../../assets/icons/explore.svg";
import documentsIcon from "../../../assets/icons/documents.svg";
import mentorIcon from "../../../assets/icons/mentor.svg";

const Navigation = () => {
  return (
    <div className={styles["navigation"]}>
      <nav>
        <ul className={styles["navigation-bar"]}>
          <li>
            <img alt="" src={menuButtonIcon} />
          </li>
          <li>
            <p>Hey, Samruddhi</p>
          </li>
          <li className={styles["navigation-bar__nav-link"]}>
            <img alt="" src={homeIcon} />
            <p>Home</p>
          </li>
          <li className={styles["navigation-bar__nav-link"]}>
            <img alt="" src={exploreIcon} />
            <p>Explore</p>
          </li>
          <li className={styles["navigation-bar__nav-link"]}>
            <img alt="" src={documentsIcon} />
            <p>Documents</p>
          </li>
          <li
            className={`${styles["navigation-bar__nav-link"]} ${styles["navigation-bar__last-link"]}`}
          >
            <img alt="" src={mentorIcon} />
            <p>Mentor</p>
          </li>
          <li className={styles["navigation-bar__blank"]}></li>
          <li className={styles["navigation-bar__notification"]}>
            <img alt="" src={bellIcon} />
          </li>
          <li>
            <img alt="" src={profileIcon} />
          </li>
        </ul>
      </nav>
    </div>

    // <div className={styles["navigation__container"]}>
    //   <nav className={styles["navigation"]}>
    //     <div className={styles["navigation__wrapper"]}>
    //       <div className={styles["navigation__menu"]}>
    //         <img alt="" src={menuButtonIcon} />
    //         <p>Hey, Samruddhi</p>
    //       </div>
    //       <div className={styles["navigation__bar"]}>
    //         <ul className={styles["navigation__nav-links"]}>
    //           <li className={styles["navigation__nav-link"]}>
    //             <img alt="" src={homeIcon} />
    //             <p>Home</p>
    //           </li>
    //           <li className={styles["navigation__nav-link"]}>
    //             <img alt="" src={exploreIcon} />
    //             <p>Explore</p>
    //           </li>
    //           <li className={styles["navigation__nav-link"]}>
    //             <img alt="" src={documentsIcon} />
    //             <p>Documents</p>
    //           </li>
    //           <li className={styles["navigation__nav-link"]}>
    //             <img alt="" src={mentorIcon} />
    //             <p>Mentor</p>
    //           </li>
    //         </ul>
    //         <div className={styles["navigation__profile"]}>
    //           <img alt="" src={bellIcon} />
    //           <img alt="" src={profileIcon} />
    //         </div>
    //       </div>
    //     </div>
    //   </nav>
    // </div>
  );
};
export default Navigation;
