import styles from "./Header.module.css";
import backgroundImage from "../../assets/background-image.svg";
import menuButton from "../../assets/icons/menu-button.svg";
import homeIcon from "../../assets/icons/home.svg";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles["header__content"]}>
        <div className={styles["background-img__container"]}>
          <img className={styles["background-img"]} src={backgroundImage} />
        </div>
      </div>
    </header>
  );
};
export default Header;
